<template>
  <div class="main">
    <div class="container-title">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click.prevent="$router.push(`/buyercenter/order-management?entityId=${$route.query.entityId}`)">订单管理</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          支付订单
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container">
      <a-card>
        <p class="fs16">待支付订单</p>
        <a-table rowKey="id" :columns="columns" :loading="loading" :data-source="data" size="middle"
          :pagination="false">
          <template slot="isPaying" slot-scope="text,row">
            <span v-if="row.isPaying == 1">存在</span>
          </template>
          <template slot="quantity" slot-scope="text, row">
            <span>{{text + row.unitsMeasure}}</span>
          </template>
          <template slot="tax">
            <a-checkbox :checked="selectkeys.length !== 0 && (selectkeys.length === data.filter(item => item.unpaidTaxAmount && item.isPaying != 1).length)"
              :indeterminate="selectkeys.length && selectkeys.length !== data.filter(item => item.unpaidTaxAmount && item.isPaying != 1).length"
              @change="reHandle">未付税款</a-checkbox>
          </template>
          <template slot="unpaidTaxAmount" slot-scope="text, row">
            <a-checkbox :checked="selectkeys.includes(row.id)" :disabled="!row.unpaidTaxAmount || row.isPaying == 1" @change="tdHandle($event, row.id)">{{text}}</a-checkbox>
          </template>
        </a-table>
      </a-card>

      <a-card class="card-b">
        <p class="fs18">支付税款：￥{{taxAll}}</p>

        <div>
          <a-radio-group v-model="value" style="width: 100%;">
            <a-radio class="l-flex-aligncenter" :style="value == 1 ? radioStyle : ''" :value="1">
              <div class="l-flex-between">
                <a-space>
                  <div class="logo">
                    <img src="@/assets/images/u7404.png" alt="" />
                  </div>
                  <p class="pt20">网关支付</p>
                </a-space>

                <p v-if="orders.bankName === '浙商银行'">
                  <span>实时到账</span>&nbsp;&nbsp;
                  <!-- (taxAll*0.0011 > 0.01 ? Math.ceil(taxAll*0.0011*100)/100 : 0.01) -->
                  <span>手续费 0 元</span>
                </p>
                <p v-else>T+1到账，平台承担手续费</p>
              </div>
            </a-radio>
          </a-radio-group>

          <a-row style="margin-top: 12px">
            <a-col :span="24">
              <span class="fl">选择支付账号：</span>
              <div class="row">
                <a-select v-model="payAccount.value" @change="handleChange" style="width: 510px">
                  <a-select-option v-for="item in payAccount.list" :key="item.bankAccount">
                    {{ item.purchaserName+' '+item.bankAccount+' '+item.bankName  }}</a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="24">
              <span class="fl">支付账户类型：</span>
              <div class="row">
                <a-input value="企业" style="width: 510px" disabled></a-input>
              </div>
            </a-col>
            <a-col :span="24">
              <span class="fl">银行账号：</span>
              <div class="row">
                <a-input ref="fou" class="bankNo" :maxLength="30" v-model="orders.bankAccountNo" style="width: 510px">
                </a-input>
              </div>
            </a-col>
            <a-col :span="24">
              <span class="fl"></span>
              <div class="row">
                <div class="end-row">
                  <a-space :size="20">
                    <a-button @click="$router.back()">关闭</a-button>
                    <a-button type="primary" :loading="loadingBtn" @click="pay">确认支付</a-button>
                  </a-space>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>
    <!-- form格式提交支付 -->
    <form :action="form.reqUrl" method="post" v-show="false" target="_blank">
      <input type="text" :value="form.method" name="method" />
      <input type="text" :value="form.version" name="version" />
      <input type="text" :value="form.merId" name="merId" />
      <input type="text" :value="form.date" name="date" />
      <input type="text" :value="form.token" name="token" />
      <input type="text" :value="form.signMethod" name="signMethod" />
      <input type="text" :value="form.sign" name="sign" />
      <input type="submit" ref="sub" />
    </form>
  </div>
</template>

<script>
import { valid, payTaxList, payBankList, payTaxUrl } from '@/api/pay'
const columns = [
  {
    title: '订单号',
    dataIndex: 'id'
  },
  {
    title: '所属合同',
    dataIndex: 'contractNo',
    align: 'center',
    scopedSlots: { customRender: 'contractNo' }
  },
  // {
  //   title: '商品名称',
  //   align: 'center',
  //   dataIndex: 'mdseName',
  // },
  // {
  //   title: '购买数量',
  //   align: 'right',
  //   dataIndex: 'quantity',
  //   scopedSlots: { customRender: 'quantity' }
  // },
  {
    title: '合同总价',
    align: 'right',
    dataIndex: 'contractAmount'
  },
  {
    title: '待支付记录',
    align: 'center',
    dataIndex: 'isPaying',
    scopedSlots: { customRender: 'isPaying' }
  },
  {
    dataIndex: 'unpaidTaxAmount',
    slots: { title: 'tax' },
    scopedSlots: { customRender: 'unpaidTaxAmount' }
  },
  {
    title: '收款方',
    align: 'center',
    dataIndex: 'regionName'
  },
  {
    title: '收款方账号',
    align: 'center',
    dataIndex: 'taxRevenueAccNo'
  }
]
export default {
  data() {
    return {
      columns,
      data: [],
      selectkeys: [],
      loading: false,
      loadingBtn: false,
      spining: false,
      value: 1,
      radioStyle: {
        background: "rgba(255, 204, 102, 1)",
      },
      payAll: {},
      orders: {
        orderIds: this.$route.query.orderIds,
        payAmount: null, // 支付金额
        bankName: '', // 银行名称
        bankAccountNo: '', // 银行卡号
        bankAccountName: '', // 支付方户名
        purchaserId: this.$route.query.entityId, // 采购商id
      },
      payAccount: {
        list: [],
        value: null
      },
      // 银行支付form
      // 银行支付
      form: {
        method: '',
        version: '',
        merId: '',
        date: '',
        token: '',
        sign: '',
        reqUrl: '',
        signMethod: ''
      },
    }
  },
  mounted() {
    this.getInfo()
    this.getList()
  },
  computed: {
    taxAll() {
      let value = 0
      for (let i = 0; i < this.data.length; i++) {
        const objI = this.data[i]
        for (let j = 0; j < this.selectkeys.length; j++) {
          const objJ = this.selectkeys[j]
          if (objI.id === objJ) {
            value += objI.unpaidTaxAmount
          }
        }
      }
      return value.toFixed(2)
    }
  },
  methods: {
    getInfo() {
      // 获取银行列表
      payBankList({ purchaserId: this.$route.query.entityId }).then(res => {
        console.log(res);
        this.payAccount.list = res.data || []
        const obj = (this.payAccount.list[0] || [])
        this.payAccount.value = obj.bankAccount
        this.orders.bankAccountNo = obj.bankAccount
        this.orders.bankName = obj.bankName
        this.orders.bankAccountName = obj.purchaserName
      })
    },
    // 获取table列表
    getList() {
      payTaxList({ orderIds: this.$route.query.orderIds }).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.data = res.data
          this.selectkeys = []
          for (let i = 0; i < this.data.length; i++) {
            const objI = this.data[i]
            if (objI.unpaidTaxAmount && objI.isPaying!= 1) {
              this.selectkeys.push(objI.id)
            }
          }
          console.log(3333);
        }
      })
    },
    // 选择支付账户操作
    handleChange(e) {
      const bankNa = this.payAccount.list.find((item) => {
        return item.bankAccount === e
      })
      this.orders.bankName = bankNa.bankName
      this.orders.bankAccountNo = e
      this.orders.bankAccountName = bankNa.purchaserName
      //   this.getVaild(bankNa.bankName === '浙商银行')
    },
    getVaild(cardNo) {
      valid({ cardNo }).then(res => {
        if (res.code === 200) {
          this.stat = res.data.stat
        } else {
          this.stat = false
        }
      })
    },
    // table td 多选框操作
    tdHandle(e, id) {
      const index = this.selectkeys.findIndex(item => item == id)
      if (index > -1) {
        this.selectkeys.splice(index, 1)
      } else {
        this.selectkeys.push(id)
      }
    },
    // table th 全选多选框操作
    reHandle(e) {
      if (!e.target.checked) {
        this.selectkeys = []
      } else {
        this.selectkeys = []
        for (let i = 0; i < this.data.length; i++) {
          const objI = this.data[i]
          if (objI.unpaidTaxAmount && objI.isPaying!= 1) {
            this.selectkeys.push(objI.id)
          }
        }
      }
    },
    // 支付
    pay() {
      if (this.taxAll == 0) {
        this.$message.error('支付税款不能为0')
        return
      }
        this.loadingBtn = true
        payTaxUrl({...this.orders, payAmount: this.taxAll}).then(res => {
          this.loadingBtn = false
          if (res.code === 200) {
            this.form = res.data
            this.$nextTick(() => {
              this.$refs.sub.click()
              this.$router.back()
            })
          } else {
            this.$message.error(res.message)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  padding: 0 15px;
}
.container-title {
  margin: auto;
  width: 1100px;
  @media screen and (max-width: 1440px) {
    width: 100%;
    max-width: 1440px;
    min-width: 990px;
  }
}
.container {
  color: rgba(0, 0, 0, 0.85);
  margin: 0 auto;
  margin-bottom: 64px;
  width: 1100px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  .card-b {
    margin-top: 20px;

    .logo {
      width: 143px;
      height: 55px;
      overflow: hidden;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 5px;
      img {
        width: 100%;
      }
      .pt5 {
        padding: 5px 0 0 0;
      }
    }
    .fl {
      line-height: 32px;
      text-align: right;
      width: 100px;
    }
    .row {
      margin-left: 100px;
      margin-bottom: 16px;
    }
  }
}
.fs16 {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-bottom: 12px;
}
.fs18 {
  font-size: 18px;
  color: #d9001b;
  margin-bottom: 12px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  .ant-radio {
    width: 16px;
  }
  span {
    &:last-child {
      width: 100%;
    }
  }
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
.end-row {
  text-align: right;
  width: 510px;
}
</style>
